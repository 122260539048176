// import { DefaultTheme } from 'styled-components';
// import { ITheme } from '../interface';

export const BREAKPOINTS = {
  MOBILE: `(max-width:450px)`,
  TABLETS: `(max-width:960px)`,
  DESKTOP: `(max-width:1920px)`,
  LARGE: `(min-width:1920px)`,
};

export const THEME = {
  pageWidth: '1190px' as '1190px',
  colors: {
    primary: '#3fb865' as '#3fb865',
    secondary: '#363b4d' as '#363b4d',
    light: {
      primary: '#fff' as '#fff',
      secondary: '#e2deed' as '#e2deed',
      tertiary: '#f7f7f7' as '#f7f7f7',
      border: '#eaeaea' as '#eaeaea',
      // TODO add light secondary
    },
    dark: {
      // TODO add dark primary
      secondary: '#1f9c92' as '#1f9c92',
      primary: '#fff' as '#fff',
      tertiary: '#f7f7f7' as '#f7f7f7',
      border: '#eaeaea' as '#eaeaea',
    },
    plainWhite: '#fff' as '#fff',
    error: '#f44336' as '#f44336',
  },
} as const;

export const MUI_THEME = {
  palette: {
    primary: {
      light: '#fff',
      main: '#3fb865',
      contrastText: '#fff',
    },
    secondary: {
      main: '#363b4d',
      contrastText: '#fff',
    },
  },
  typography: {
    // Use the system font.
    htmlFontSize: 13,
    fontSize: 13,
    fontFamily: `Quicksand,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica Neue,Arial,sans-serif`,
  },
};

export const SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/rentablepk/',
  TWITTER: 'https://twitter.com/rentablepk',
  INSTAGRAM: 'https://www.instagram.com/rentable.pk/',
  RSSFEED: 'https://blog.rentable.pk/feed',
};

export const REPORT_LISTING_OPTIONS = [
  {
    id: 'sold',
    name: 'The item has been rented',
  },
  {
    id: 'duplicated',
    name: 'The item is duplicated',
  },
  {
    id: 'badContent',
    name: 'Content is not correct',
  },
  {
    id: 'fraud',
    name: 'This is illegal/fraudulent',
  },
  {
    id: 'other',
    name: 'Other reason',
  },
];

export const APPSTORE_LINKS = {
  IOS: 'https://apps.apple.com/pk/app/rentable-pakistan/id1508744193',
  ANDROID: 'https://play.google.com/store/apps/details?id=pk.com.rentable',
};

export const DEEP_LINK = 'https://rentablepk.page.link/app';

export const FOOTER_LISTINGS_SECTIONS = {
  LOCATIONS: [
    { slug: 'islamabad', name: 'Islamabad' },
    { slug: 'rawalpindi', name: 'Rawalpindi' },
    { slug: 'lahore', name: 'Lahore' },
    { slug: 'karachi', name: 'Karachi' },
    { slug: 'peshawar', name: 'Peshawar' },
    { slug: 'multan', name: 'Multan' },
    { slug: 'gujranwala', name: 'Gujranwala' },
    { slug: 'sialkot', name: 'Sialkot' },
    { slug: 'quetta', name: 'Quetta' },
  ],
  CATEGORIES: [
    // { slug: 'books-sports-hobbies', name: 'Books, Sports & Hobbies' },
    // { slug: 'fashion-beauty', name: 'Fashion & Beauty' },
    { slug: 'property', name: 'Property' },
    { slug: 'automobiles', name: 'Automobiles' },
    { slug: 'electronics-home-appliances', name: 'Electronics' },
    // { slug: 'furniture-home-decor', name: 'Furniture & Home Decor' },
  ],
};

export const TIPS = [
  'Always inspect items before paying',
  'Beware of underpriced offers.',
  'Be careful when renting out high-value items.',
  'Never share your financial information to make or accept a rental.',
  'Beware of unusual/suspicious requests',
  'Avoid locations that you are unfamiliar with',
  'The location you plan to meet the renter must be a safe place.',
  'Verify the rental agreements properly with details',
  'Make sure that you get the full advance or security deposit for the services or goods that you advertise in our site before closing the deal.',
  'Before accepting the money orders or currency notes, check them thoroughly.',
];
