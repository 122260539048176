export default {
  isDevMode: process.env.NODE_ENV === `development`,
  isProdMode: process.env.NODE_ENV === `production`,
  appVersion: 'v1',
  appEnvironment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  devUrl: process.env.NEXT_PUBLIC_DEV_BASE_URL,
  apiVersion: process.env.NEXT_PUBLIC_API_VERSION,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  mailChimpUrl: process.env.NEXT_PUBLIC_MAIL_CHIMP_URL,
  facebookId: process.env.NEXT_PUBLIC_FACEBOOK_ID,
  googleId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  googleId2: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  googleAdSenseClient: process.env.NEXT_PUBLIC_GOOGLE_ADSENSE,
  googleAdSenseSlot: process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_SLOT,
  blogAPIKey: process.env.NEXT_PUBLIC_BLOG_API_KEY,
  blogURL: process.env.NEXT_PUBLIC_BLOG_URL,
};
